import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { NavCard } from '../../models/navigation.models';

@Component({
	selector: 'uc-nav-card-landscape',
	templateUrl: './nav-card-landscape.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardLandscapeComponent extends BaseNavlinkComponent {
	data = input.required<NavCard>();
	linkClicked = output<void>();
	impressionMade = output<void>();

	onClick() {
		this.closeNav();
		this.linkClicked.emit();
	}

	onImpression() {
		this.impressionMade.emit();
	}
}
