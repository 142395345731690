import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { clearing } from '../../../static/subnav-clearing.properties';
import {
	getStateNavClearingUnis,
	getStateNavClearingArticles,
} from '../../../data-access/+store/navigation.selectors';
import { UniversityGTMService } from '@uc/web/core';
import { NavCard } from '../../../models/navigation.models';
import { ProductEnum } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-subnav-clearing',
	templateUrl: './subnav-clearing.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavClearingComponent {
	data = clearing;
	clearingUnis$ = this._store.select(getStateNavClearingUnis);
	clearingArticles$ = this._store.select(getStateNavClearingArticles);

	constructor(
		private _store: Store,
		private _uniGTMSrv: UniversityGTMService,
	) {}

	onClick(card: NavCard) {
		if (!card.uniId || !card.uniName) return;
		this._uniGTMSrv.uniProfileClick(
			card.uniId,
			card.uniName,
			null, //todo-cleso
			ProductEnum.Clearing,
		);
	}

	onImpression(card: NavCard) {
		if (!card.uniId || !card.uniName) return;
		this._uniGTMSrv.uniImpression(
			card.uniId,
			card.uniName,
			null, //todo-cleso
			ProductEnum.Clearing,
		);
	}
}
