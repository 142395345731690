import {
	Product,
	ProductEnum,
	RecommendedClearingUniversity,
	RecommendedUniversity,
} from '@uc/web/shared/data-models';
import { Link, NavCard } from '../models/navigation.models';
import { ArticleSummary } from '@uc/web/shared/data-models';

export const formatUnisData = (
	unis: Partial<RecommendedUniversity>[] | Partial<RecommendedClearingUniversity>[],
	product: Product,
) => {
	const isUg = product === ProductEnum.Undergraduate;
	const result: NavCard[] = [];

	unis.forEach((uni: any) => {
		const region = uni.address ? uni.address.region : uni.region;
		const recommended = uni.stats
			? uni.stats.recommended_percentage
			: uni.recommended_percentage;
		const { description, noReviews } = generateDescription(recommended, region);
		const url = isUg ? `${uni.slug}` : `${uni.slug}/clearing`;

		const editedObject = {
			image: isUg ? uni.profile.cover_image : uni.cover_image,
			heading: uni.name,
			description: description,
			href: `/universities/${url}`,
			title: `Explore ${uni.name}`,
			external: false,
			slug: uni.slug,
			noReviews,
			uniId: uni.id,
			uniName: uni.name,
			uniOrder: uni.order,
		};

		result.push(editedObject);
	});

	return result;
};

export const formatArticlesData = (editorsChoice: ArticleSummary[]) => {
	const recommendedArticles: Link[] = [];
	editorsChoice.forEach((article) => {
		createLinkObj(article, recommendedArticles);
	});
	return recommendedArticles;
};

export const formatClearingArticlesData = (
	UCAS: ArticleSummary[],
	ALevels: ArticleSummary[],
) => {
	let clearingArticles: Link[] = [];

	UCAS.forEach((article) => {
		if (article.art_id === 455 || article.art_id === 456 || article.art_id === 457) {
			if (!article.url.startsWith('advice/ucas/')) {
				article.url = `advice/ucas/${article.url}`;
			}
			createLinkObj(article, clearingArticles);
		}
	});

	ALevels.forEach((article) => {
		if (article.art_id === 239) {
			if (!article.url.startsWith('advice/a-levels/')) {
				article.url = `advice/a-levels/${article.url}`;
			}
			createLinkObj(article, clearingArticles);
		}
	});

	//Rearrange order of array
	const firstArticle = clearingArticles[1];
	clearingArticles = clearingArticles.filter(
		(article) => article.title !== firstArticle.title,
	);
	clearingArticles.unshift(firstArticle);
	return clearingArticles;
};

export const createLinkObj = (article: ArticleSummary, articles: Link[]) => {
	const editedObject = {
		title: article.title,
		value: article.url,
		description: article.quote.split(' ').slice(0, 7).join(' ') + '...',
	};
	articles.push(editedObject);
};

export const generateDescription = (recommended: number, region: string) => {
	if (!recommended) {
		return {
			description: region,
			noReviews: true,
		};
	}

	return {
		description: `${region} · ${recommended}% Recommended`,
		noReviews: false,
	};
};
